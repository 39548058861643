<template>
  <div email-provide class="terms">
    <div class="contents">
      <section>
        <h1>{{ $t('_.provideEmail') }}</h1>
        {{ $t('_.detailEmail') }}
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailProvide',
  lexicon: 'extra.terms',
};
</script>
