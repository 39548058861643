<template>
  <span radio-item :class="[type, {disabled}]" @click="click">
    <i :class="{ checked }"></i>
    <span>
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  name: 'RadioItem',
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    selected: { default: null },
    value: { default: null },
    type: { type: String },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    checked() {
      return this.selected === this.value;
    },
  },
  methods: {
    click() {
      if (this.disabled) return;
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[radio-item] { .ib; .pointer;
  i { .ib; .wh(1.143em); .br(50%); .vam; .-a(#d5d5de); .mr(0.5715em);
    &.checked { .-a(@c-title-black); .rel;
      &:after { .cnt; .abs; .lt(0.2143em, 0.2143em); .wh(0.5715em); .br(50%); .bgc(@c-title-black); }
    }
  }
  span { .ib; .vam; }
  &.box { .h(40); .p(0, 16); .ib; .rel;
    i { .-a; .mr(0); .wh(100%); .br(0); .abs; .br(8); .bgc(@c-base-gray); .z(1); .lt(0, 0);
      &.checked { .-a; .bgc(@c-title-black);
        &:after { content: none;}
        & + span {.c(white);}
      }
    }
    span { .rel; .z(2); .fs(14, 40px);}
  }
  &.disabled { cursor: not-allowed; }
}

.dark {
  [radio-item] {
    i { .-a(@c-w05); .br(0);
      &.checked { .-a(#fff);
        &:after { .bgc(#fff); .br(0); }
      }
    }
  }
}
</style>
