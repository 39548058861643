<template>
  <div service-terms class="terms">
    <div class="contents">
      <section v-html="$t('_.service')">

      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceTerms',
  lexicon: 'extra.terms'
};
</script>
