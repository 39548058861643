<template>
  <div privacy-policy class="terms">
    <div class="contents">
      <section v-html="$t('_.privacy')">
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  lexicon:'extra.terms'
};
</script>
