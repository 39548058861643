<template>
  <div membership-agreement>
    <h2>AGREEMENT TERMS</h2>
    <h3>{{ $t('_.title') }}</h3>
    <div class="check-age">
      <RadioItem :value="true" v-model="olderFourteen">{{ $t('_.over14Age') }}</RadioItem>
      <RadioItem :value="false" v-model="olderFourteen">{{ $t('_.under14Age') }}</RadioItem>
    </div>
    <div class="scroll-area">
      <div class="checkbox-group">
        <CheckboxItem v-model="allAgreeChecked">{{ $t('_.agreeAll') }}</CheckboxItem>
      </div>
      <div class="checkbox-group">
        <CheckboxItem v-model="agreeTermsConditions">({{ $t('essential') }}) {{ $t('_.termsOfService') }}</CheckboxItem>
        <div class="checkbox-sub">
          <ServiceTerms />
        </div>
      </div>
      <div class="checkbox-group">
        <CheckboxItem v-model="agreePrivacyPolicy">({{ $t('essential') }}) {{ $t('_.privacyPolicy') }}</CheckboxItem>
        <div class="checkbox-sub">
          <PrivacyPolicy />
        </div>
      </div>
      <div class="checkbox-group">
        <CheckboxItem v-model="agreePrivacyPolicy">({{ $t('essential') }}) {{ $t('_.emailProvided') }}</CheckboxItem>
        <div class="checkbox-sub">
          <EmailProvide />
        </div>
      </div>
    </div>
    <PrimaryButton :disabled="!allAgreeChecked" color="red" @click="next">{{ $t('next') }}</PrimaryButton>
  </div>
</template>

<script>
import CheckboxItem from '@shared/components/common/CheckboxItem.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import { mutate } from '@shared/utils/storeUtils';
import RadioItem from '@/views/components/general/RadioItem.vue';
import ServiceTerms from '@/views/components/terms/ServiceTerms.vue';
import PrivacyPolicy from '@/views/components/terms/PrivacyPolicy.vue';
import EmailProvide from '@/views/components/terms/EmailProvide.vue';

export default {
  name: 'MembershipAgreement',
  lexicon: 'account.agreement',
  props: { reverification: { type: Boolean, default: false } },
  components: { EmailProvide, PrimaryButton, PrivacyPolicy, ServiceTerms, CheckboxItem, RadioItem },
  computed: {
    allAgreeChecked: {
      get() {
        return this.agreeTermsConditions && this.agreePrivacyPolicy && this.agreeProvideEmail;
      },
      set(v) {
        this.agreeTermsConditions = v;
        this.agreePrivacyPolicy = v;
        this.agreeProvideEmail = v;
      },
    },
    olderFourteen: mutate('signup', 'olderFourteen'),
    agreeTermsConditions: mutate('signup', 'agreeTermsConditions'),
    agreePrivacyPolicy: mutate('signup', 'agreePrivacyPolicy'),
    agreeProvideEmail: mutate('signup', 'agreeProvideEmail'),
  },
  methods: {
    next() {
      if (this.reverification) {
        this.$router.push(this.olderFourteen ? 'reverification-verification' : 'reverification-representative');
      } else if (this.$services.auth.onSocialSignupProcess) {
        this.$router.push(this.olderFourteen ? 'verification' : 'representative');
      } else {
        this.$router.push('form');
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[membership-agreement] {
  .check-age { .-b(@c-base-gray); .mb(24); .pb(20); .tc;
    [radio-item] + [radio-item] { .ml(40); }
  }
  .scroll-area { .h(288);
    .checkbox-group { .mb(20); }
    [checkbox-item] { .fs(13); .c(#787781); }
    .checkbox-sub { .pl(30); .mt(10); }
    .terms {
      .contents { .fs(12, 15); .c(#787781); .bgc(@c-base-gray); .scroll; .br(12); .h(80);
        section { .p(16); }
        h1 { .hide; }
        h2 { .noto; .fs(12, 15); .tl; .mt(40); .mb(22); }
        a { .c(#979797); }
        h1 + h2 { .mt(0); }
        p + ol { .pl(10); }
        ol, ul {
          ol, ul { .pl(16); .mt(15); }
        }
        p, li { .mb(12); .ls(-0.3);
          p, li { .c(rgba(0, 0, 0, .4)); }
        }
        table { .mt(12); .br(12); .crop; .-a(#d5d5de); .wf; border-collapse: separate; border-spacing: 0; table-layout: fixed;
          th, td { .p(12); .-b(#d5d5de); }
          th { .w(30%); .-r(#d5d5de); }
          tr:last-child {
            th, td { .-b; }
          }
        }
      }
    }
  }
  [primary-button] { .wh(100%, 56); .br(28); .fs(16); .mt(8); }

  @media (@tp-up) {
    .scroll-area { .h; }
  }
}

</style>
